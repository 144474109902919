
















































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import ReusableButton from "@/components/ReusableButton.vue";
import {
  Configuration,
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard
} from "@/configuration";
import API from "@/api";
import { EventBus } from "@/main";
import { mapState } from "vuex";

@Component({
  metaInfo: {
    title: "Editar empresa",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["setOpenMenuPanel", "menu"]),
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      btn: {
        clickable: false,
        loading: false,
        isOk: null,
        successText: "Guardado correctamente",
        errorText: "Error interno"
      },
      oldCompany: null,
      company: null,
      countries: null,
      regions: null,
      communes: null,
      radioOption: false,
      siiPassword: false,
      targets: [
        {
          id: 1,
          name: "Personas"
        },
        {
          id: 2,
          name: "Empresas"
        },
        {
          id: 3,
          name: "Ambas"
        }
      ],
      rules: {
        notEmpty: [(v: string) => !!v || "Este campo es requerido"]
      }
    };
  },
  watch: {
    company: {
      handler(val) {
        if (this.$refs.editForm != null) {
          // @ts-ignore
          if (this.$refs.editForm.validate()) {
            // @ts-ignore
            this.$data.btn.clickable = this.hasChange();
          } else {
            this.$data.btn.clickable = false;
          }
        }
      },
      deep: true
    }
  },
  async beforeMount() {
    let company = await API.getCompany(this.$props.companyId);

    let temp1 = {};
    let temp2 = {};

    Object.assign(temp1, company);
    Object.assign(temp2, company);

    this.$data.company = temp1;
    this.$data.oldCompany = temp2;

    this.$data.countries = await API.getCountries();
    // this.$data.regions = await API.getRegions();
    // this.$data.communes = await API.getCommunes(this.$data.regions[0].id);

    // @ts-ignore
    this.$data.btn.clickable = this.hasChange();
  },
  methods: {
    editCompany() {
      if (SelectedCountry == "PE") {
        this.$data.siiPassword = 123456;
      }
      // @ts-ignore
      if (this.$refs.editForm.validate()) {
        this.$data.btn.loading = true;
        API.editCompany(this.$data.company)
          .then(result => {
            this.$data.oldCompany = this.$data.company;
            this.$data.company = this.$data.oldCompany;
            this.$data.btn.isOk = true;
            setTimeout(() => {
              //@ts-ignore
              if(this.menu == 2){
                this.$router.push({
                  path: "/panel/mis-empresas"
                });
              }else{
                this.$router.push({
                  path: "/panel-facto/empresas"
                });
              }
            }, Configuration.Redirection.timeout);
            // @ts-ignore
            this.$data.btn.clickable = this.hasChange();
          })
          .catch(error => {
            EventBus.$emit("snack-error", error);
            this.$data.btn.isOk = false;
          });
      }
    },
    hasChange() {
      let oldData = Object.values(this.$data.oldCompany);
      let newData = Object.values(this.$data.company);

      let hasSomethingChange = false;

      for (let i in oldData) {
        if (oldData[i] != newData[i]) {
          if (newData[i] == "") {
            continue;
          }
          hasSomethingChange = true;
        }
      }

      return hasSomethingChange;
    },
    // async updateRegions() {
    //   this.$data.regions = await API.getRegions();
    //   this.$data.company.region_id = this.$data.regions[0].id;
    //   // @ts-ignore
    //   this.updateCommunes();
    // },
    // async updateCommunes() {
    //   this.$data.communes = await API.getCommunes(this.$data.company.region_id);
    //   this.$data.company.comuna_id = this.$data.communes[0].id;
    // }
  },
  components: {
    ReusableButton
  }
})
export default class EditBusiness extends Vue {
  @Prop() companyId!: number;
}
